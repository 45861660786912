<template>
  <div id="role">
    <el-dialog
      :title="roleFormTitle"
      width="880px"
      :visible.sync="roleDialogVisible"
      :close-on-click-modal="false"
      @close="roleDialogClose"
    >
      <el-form
        ref="roleFormRef"
        :model="roleForm"
        :rules="roleFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="名称" prop="name">
              <el-input v-model="roleForm.name" placeholder="请输入名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="编码" prop="code">
              <el-input v-model="roleForm.code" placeholder="请输入编码" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="排序" prop="sort">
              <el-input-number v-model="roleForm.sort" placeholder="请输入排序" controls-position="right" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="菜单" prop="menuIds">
          <el-scrollbar style="height: 450px">
            <el-tree
              ref="menuTreeRef"
              :default-expand-all="false"
              :data="menuTreeWithPermission"
              :props="{label: 'name', children: 'children'}"
              node-key="id"
              show-checkbox
            />
          </el-scrollbar>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="roleDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="roleFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item>
        <el-button
          v-if="checkPermission(['ROLE_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="roleList"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeightNoPage()"
    >
      <el-table-column prop="name" label="名称" />
      <el-table-column prop="code" label="编码" />
      <el-table-column prop="sort" label="排序" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['ROLE_DELETE'])"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['ROLE_UPDATE'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {
  addRole,
  deleteRole,
  updateRole,
  selectRoleInfo,
  selectRoleList
} from '@/api/system/role'
import { selectMenuTreeWithPermission } from '@/api/system/menu'

export default {
  data () {
    return {
      roleDialogVisible: false,
      roleFormTitle: '',
      roleForm: {
        id: '',
        name: '',
        code: '',
        sort: '',
        menuIds: ''
      },
      roleFormRules: {
        name: [{ required: true, message: '名称不能为空', trigger: ['blur', 'change']}],
        code: [{ required: true, message: '编码不能为空', trigger: ['blur', 'change']}],
        menuIds: [{ required: true, message: '菜单不能为空', trigger: ['']}]
      },
      roleList: [],
      menuTreeWithPermission: [],
      checkedMenus: []
    }
  },
  created () {
    selectRoleList().then(res => {
      this.roleList = res
    })
    selectMenuTreeWithPermission().then(res => {
      this.menuTreeWithPermission = res
    })
  },
  methods: {
    getCheckedMenus (tree, list, checkeds) {
      if (!tree || tree.length === 0) {
        return
      }
      if (!list || list.length === 0) {
        return
      }
      for (let i = 0; i < tree.length; i++) {
        for (let j = 0; j < list.length; j++) {
          if (tree[i].id === list[j].id) {
            if (!tree[i].children || tree[i].children.length === 0) {
              checkeds.push(tree[i].id)
            } else {
              this.getCheckedMenus(tree[i].children, list, checkeds)
            }
          }
        }
      }
    },
    roleDialogClose () {
      this.$refs.roleFormRef.resetFields()
      this.$refs.menuTreeRef.setCheckedKeys([])
      this.checkedMenus = []
    },
    roleFormSubmit () {
      this.roleForm.menuIds = this.$refs.menuTreeRef.getCheckedNodes(false, true).map(menu => menu.id).join(',')
      this.$refs.roleFormRef.validate(async valid => {
        if (valid) {
          if (this.roleFormTitle === '新增角色') {
            await addRole(this.roleForm)
          } else if (this.roleFormTitle === '修改角色') {
            await updateRole(this.roleForm)
          }
          this.roleList = await selectRoleList()
          this.roleDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.roleFormTitle = '新增角色'
      this.roleDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteRole(row.id)
        this.roleList = await selectRoleList()
      })
    },
    handleUpdate (index, row) {
      this.roleFormTitle = '修改角色'
      this.roleDialogVisible = true
      selectRoleInfo(row.id).then(res => {
        this.roleForm.id = res.id
        this.roleForm.name = res.name
        this.roleForm.code = res.code
        this.roleForm.sort = res.sort
        this.getCheckedMenus(this.menuTreeWithPermission, res.menuList, this.checkedMenus)
        this.$refs.menuTreeRef.setCheckedKeys(this.checkedMenus)
      })
    }
  }
}
</script>

<style>
#role .el-scrollbar .el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>
